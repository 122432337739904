<template>
    <div v-if="horse">
        <div>
            <shutter-panel ref="shutterPanel" />
        </div>
    </div>
</template>

<script>
import HorseMixin from "@/mixins/Horse.js"
import Shutter from "@/mixins/Shutter.js"
import ShutterGyneco from "@/mixins/shutters-manager/Gyneco.js"

export default {
    name: 'GynecologieHorseSummary',
    mixins: [HorseMixin, Shutter, ShutterGyneco],
    props: {
        horse: { type: Object, default: null },
        horse_id: { type: String, default: '' }
    },
    data() {
        return {
            local_horse: null
        }
    },
    mounted() {
        // Si c'est un enfant, le init component complet se fera au hook onOpen
        this.init_component()
    },
    methods: {
        async init_component() {
            if(!this.horse && (this.horse_id)) {
                // On charge les infos du cheval
                this.getHorseById(this.horse_id)
                .then(result => {
                    this.local_horse = result
                    this.goToGynecoSummary()
                })
                .catch(e => {
                    console.log(e)
                    this.local_horse = null
                })
            }
            else {
                this.local_horse = this.horse
                this.goToGynecoSummary()
            }
        },
        /* Si on est enfant, et qu'on a un hook d'ouvertur du volet */
        async onOpen() {
            this.init_component()
        },
        goToGynecoSummary() {
            this.setupHorseGyneco(this.local_horse)
        }
    },
    watch: {
        horse(val) {
            if(val) return this.init_component()
        }
    }
}
</script>
